@charset "UTF-8";

.dsr-toast {
    --dsr-toast-border-left: 0.6rem;
    --dsr-toast-size-2: 0.2rem;
    --dsr-toast-size-20: 2rem;
    --dsr-toast-size-18: 1.8rem;
    --dsr-toast-size-15: 1.5rem;
    --dsr-toast-content-height: 4rem;
    --dsr-toast-title-font: 1.8rem;
    --dsr-toast-text-font: 1.6rem;
    --dsr-toast-close-radius: 1.5rem;
    --dsr-toast-close-size: 2.8rem;
    --dsr-toast-close-line-height: 2.9rem;
    background-color: var(--dsr-color-white)
}

.dsr-toast,
.dsr-toast--info {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: var(--dsr-toast-border-left) solid var(--dsr-color-primary);
    padding: var(--dsr-toast-size-20) var(--dsr-spacing-m) var(--dsr-toast-size-20) var(--dsr-toast-size-18);
    box-shadow: 0 0 var(--dsr-spacing) 0 rgba(219, 219, 219, .5)
}

.dsr-toast--success {
    border-left-color: var(--dsr-color-success)
}

.dsr-toast--danger {
    border-left-color: var(--dsr-color-error)
}

.dsr-toast--warning {
    border-left-color: var(--dsr-color-warning)
}

.dsr-toast__content {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: center;
    min-height: var(--dsr-toast-content-height)
}

.dsr-toast__title {
    font-family: "Read Medium";
    font-style: normal;
    font-size: var(--dsr-toast-title-font);
    color: var(--dsr-color-dark-3)
}

.dsr-toast__text {
    font-family: var(--dsr-font-main);
    font-style: normal;
    font-size: var(--dsr-toast-text-font);
    color: var(--dsr-color-black);
    padding-top: var(--dsr-toast-size-2)
}

.dsr-toast__ico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: var(--dsr-toast-size-15)
}

.dsr-toast__close {
    border: var(--dsr-spacing-line) solid var(--dsr-color-black);
    border-radius: var(--dsr-toast-close-radius);
    cursor: pointer
}

.dsr-toast__close:before {
    content: "✕";
    display: inline-block;
    width: var(--dsr-toast-close-size);
    height: var(--dsr-toast-close-size);
    text-align: center;
    vertical-align: middle;
    line-height: var(--dsr-toast-close-line-height);
    margin: 0
}

.dsr-toast--colored,
.dsr-toast--colored.dsr-toast--info {
    background: var(--dsr-color-primary)
}

.dsr-toast--colored.dsr-toast--success {
    background-color: var(--dsr-color-green-hover)
}

.dsr-toast--colored.dsr-toast--danger {
    background-color: var(--dsr-color-red-light)
}

.dsr-toast--colored.dsr-toast--warning {
    background-color: var(--dsr-color-orange-hover)
}

.dsr-toast--colored .dsr-toast__close {
    border: none;
    background-color: var(--dsr-color-white)
}

:root {
    --dsr-color-secondary: #FFDB75;
    --dsr-color-orange-dark: #EB6500;
    --dsr-color-text: #000000;
    --dsr-color-background: #EEEEEE;
    --dsr-color-darkgreen-light: #7CF7CF;
    --dsr-color-info: #FFCC33;
    --dsr-color-orange: #FF8200;
    --dsr-color-darkblue: #0033A0;
    --dsr-color-darkpurple: #5C068C;
    --dsr-color-light-1: #FAF9F8;
    --dsr-color-text-link: #0091DA;
    --dsr-color-red-light: #FF6E6A;
    --dsr-color-red-dark: #8C0012;
    --dsr-color-blueduck: #009CA6;
    --dsr-color-light-2: #F1F1F2;
    --dsr-color-ze-light: #B2DEF3;
    --dsr-color-warning: #FF8200;
    --dsr-color-hover: #BBBCBC;
    --dsr-color-darkgreen-hover: #55D2AC;
    --dsr-color-yellow: #FFCC33;
    --dsr-color-text-secondary: #999999;
    --dsr-color-darkgreen-dark: #006747;
    --dsr-color-black: #000000;
    --dsr-color-orange-light: #FFC14C;
    --dsr-color-red-hover: #E85252;
    --dsr-color-darkblue-light: #006ED6;
    --dsr-color-ze-hover: #4CB2E5;
    --dsr-color-dark-1: #888B8D;
    --dsr-color-blue: #0077C8;
    --dsr-color-primary: #FFCC33;
    --dsr-color-success: #73C366;
    --dsr-color-dark-2: #53565A;
    --dsr-color-darkpurple-light: #AD5DDE;
    --dsr-color-green: #73C366;
    --dsr-color-blueduck-light: #77EEF8;
    --dsr-color-dark-3: #333333;
    --dsr-color-purple: #9E007E;
    --dsr-color-yellow-light: #FFF8E6;
    --dsr-color-orange-hover: #FFA51C;
    --dsr-color-purple-dark: #810065;
    --dsr-color-darkblue-hover: #1749C4;
    --dsr-color-error: #CB333B;
    --dsr-color-green-dark: #4EA147;
    --dsr-color-white: #FFFFFF;
    --dsr-color-darkpurple-hover: #9143C2;
    --dsr-color-blue-dark: #0048A0;
    --dsr-color-blueduck-hover: #58D1DC;
    --dsr-color-yellow-hover: #FFDB75;
    --dsr-color-blue-light: #7AC5FF;
    --dsr-color-green-light: #A7FB9A;
    --dsr-color-purple-light: #D750B3;
    --dsr-color-darkgreen: #009B77;
    --dsr-color-blueduck-dark: #006772;
    --dsr-color-blue-hover: #5BAAFF;
    --dsr-color-darkpurple-dark: #430068;
    --dsr-color-red: #CB333B;
    --dsr-color-green-hover: #8BDE7F;
    --dsr-color-purple-hover: #BA3198;
    --dsr-color-darkblue-dark: #002280;
    --dsr-color-ze: #0091DA
}

:root {
    --dsr-font-title: 'Renault Life', 'Read', 'Arial', sans-serif;
    --dsr-font-weight-bold: 700;
    --dsr-font-weight-extralight: 200;
    --dsr-font-weight-normal: 400;
    --dsr-font-weight: normal;
    --dsr-font-line-height: 1.1;
    --dsr-font-main: 'Read', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --dsr-font-weight-semibold: 600;
    --dsr-font-weight-light: 300;
    --dsr-font-weight-medium: 500
}

:root {
    --dsr-size-body: 1.4rem;
    --dsr-size-caption: 1.2rem;
    --dsr-size-hero-headline: 4.8rem;
    --dsr-size-subtitle-big: 1.6rem;
    --dsr-size-h6-headline: 1.8rem;
    --dsr-size-h5-headline: 2rem;
    --dsr-size-h4-headline: 2.4rem;
    --dsr-size-button: 1.4rem;
    --dsr-size-legal-mention: 1.2rem;
    --dsr-size-h3-headline: 2.8rem;
    --dsr-size-h2-headline: 3.2rem;
    --dsr-size-h1-headline: 3.8rem;
    --dsr-size-body-big: 1.6rem;
    --dsr-size-subtitle: 1.4rem;
    --dsr-size-quote: 1.6rem
}

:root {
    --dsr-spacing-xxxl: 10.4rem;
    --dsr-spacing-xl: 4rem;
    --dsr-spacing-line: 0.1rem;
    --dsr-spacing-app-min-width: 30rem;
    --dsr-spacing-xs: 0.8rem;
    --dsr-spacing-l: 3.2rem;
    --dsr-spacing-xxl: 7.2rem;
    --dsr-spacing-m: 2.4rem;
    --dsr-spacing: 1rem;
    --dsr-spacing-s: 1.6rem
}