@charset "UTF-8";

@font-face {
    font-family: DesyreIcons;
    src: url(./DesyreIcons.eot?fjw8jb);
    src: url(./DesyreIcons.eot?fjw8jb#iefix) format("embedded-opentype"),
        url(./DesyreIcons.ttf?fjw8jb) format("truetype"), url(./DesyreIcons.woff?fjw8jb) format("woff"),
        url(./DesyreIcons.svg?fjw8jb#DesyreIcons) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

.dsr-icon {
    font-family: DesyreIcons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dsr-icon--xs {
    font-size: .5rem
}

.dsr-icon--s {
    font-size: 1rem
}

.dsr-icon--m {
    font-size: 1.5rem
}

.dsr-icon--l {
    font-size: 2rem
}

.dsr-icon--xl {
    font-size: 3rem
}

.dsr-icon--primary {
    color: var(--dsr-color-yellow)
}

.dsr-icon--green {
    color: var(--dsr-color-green)
}

.dsr-icon--blue {
    color: var(--dsr-color-blue)
}

.dsr-icon--red {
    color: var(--dsr-color-red)
}

.dsr-icon.negative,
.dsr-icon.negative * {
    color: var(--dsr-color-white)
}

.dsr-icon.dsr-aa00-fake-icon:before {
    content: "\f101";
}

.dsr-icon.dsr-commercial-agreement:before {
    content: "\f102";
}

.dsr-icon.dsr-commercial-bag:before {
    content: "\f103";
}

.dsr-icon.dsr-commercial-calculator:before {
    content: "\f104";
}

.dsr-icon.dsr-commercial-car-delivery:before {
    content: "\f105";
}

.dsr-icon.dsr-commercial-coins:before {
    content: "\f106";
}

.dsr-icon.dsr-commercial-copyright:before {
    content: "\f107";
}

.dsr-icon.dsr-commercial-credit-card:before {
    content: "\f108";
}

.dsr-icon.dsr-commercial-dealer-dacia:before {
    content: "\f109";
}

.dsr-icon.dsr-commercial-dealer-dealer-flag:before {
    content: "\f10a";
}

.dsr-icon.dsr-commercial-dealer-renault:before {
    content: "\f10b";
}

.dsr-icon.dsr-commercial-dealer-rental:before {
    content: "\f10c";
}

.dsr-icon.dsr-commercial-dealer-upgrade:before {
    content: "\f10d";
}

.dsr-icon.dsr-commercial-euro:before {
    content: "\f10e";
}

.dsr-icon.dsr-commercial-goal:before {
    content: "\f10f";
}

.dsr-icon.dsr-commercial-key-delivery:before {
    content: "\f110";
}

.dsr-icon.dsr-commercial-money:before {
    content: "\f111";
}

.dsr-icon.dsr-commercial-promo:before {
    content: "\f112";
}

.dsr-icon.dsr-commercial-refund:before {
    content: "\f113";
}

.dsr-icon.dsr-commercial-sale:before {
    content: "\f114";
}

.dsr-icon.dsr-commercial-shopping-cart:before {
    content: "\f115";
}

.dsr-icon.dsr-commercial-stat-bar:before {
    content: "\f116";
}

.dsr-icon.dsr-commercial-stat-circle:before {
    content: "\f117";
}

.dsr-icon.dsr-commercial-timer-4min:before {
    content: "\f118";
}

.dsr-icon.dsr-factory-factory:before {
    content: "\f119";
}

.dsr-icon.dsr-factory-foundry:before {
    content: "\f11a";
}

.dsr-icon.dsr-factory-gear:before {
    content: "\f11b";
}

.dsr-icon.dsr-factory-graph:before {
    content: "\f11c";
}

.dsr-icon.dsr-factory-mechanical:before {
    content: "\f11d";
}

.dsr-icon.dsr-factory-paint-bucket:before {
    content: "\f11e";
}

.dsr-icon.dsr-factory-painting:before {
    content: "\f11f";
}

.dsr-icon.dsr-factory-repair:before {
    content: "\f120";
}

.dsr-icon.dsr-factory-sonde:before {
    content: "\f121";
}

.dsr-icon.dsr-factory-synoptique:before {
    content: "\f122";
}

.dsr-icon.dsr-factory-toolbox:before {
    content: "\f123";
}

.dsr-icon.dsr-nav-action-adjustement-filter-az:before {
    content: "\f124";
}

.dsr-icon.dsr-nav-action-adjustement-filter-za:before {
    content: "\f125";
}

.dsr-icon.dsr-nav-action-adjustement-font-increase:before {
    content: "\f126";
}

.dsr-icon.dsr-nav-action-adjustement-font-reduce:before {
    content: "\f127";
}

.dsr-icon.dsr-nav-action-adjustement-full-screen:before {
    content: "\f128";
}

.dsr-icon.dsr-nav-action-adjustement-less:before {
    content: "\f129";
}

.dsr-icon.dsr-nav-action-adjustement-more:before {
    content: "\f12a";
}

.dsr-icon.dsr-nav-action-adjustement-pip:before {
    content: "\f12b";
}

.dsr-icon.dsr-nav-action-adjustement-reduce-screen:before {
    content: "\f12c";
}

.dsr-icon.dsr-nav-action-adjustement-zoom-in:before {
    content: "\f12d";
}

.dsr-icon.dsr-nav-action-adjustement-zoom-out:before {
    content: "\f12e";
}

.dsr-icon.dsr-nav-action-call:before {
    content: "\f12f";
}

.dsr-icon.dsr-nav-action-cross:before {
    content: "\f130";
}

.dsr-icon.dsr-nav-action-drop-files:before {
    content: "\f131";
}

.dsr-icon.dsr-nav-action-edit:before {
    content: "\f132";
}

.dsr-icon.dsr-nav-action-export:before {
    content: "\f133";
}

.dsr-icon.dsr-nav-action-filter:before {
    content: "\f134";
}

.dsr-icon.dsr-nav-action-header-burger-menu:before {
    content: "\f135";
}

.dsr-icon.dsr-nav-action-header-disconnect:before {
    content: "\f136";
}

.dsr-icon.dsr-nav-action-header-exit:before {
    content: "\f137";
}

.dsr-icon.dsr-nav-action-header-galaxy:before {
    content: "\f138";
}

.dsr-icon.dsr-nav-action-header-home:before {
    content: "\f139";
}

.dsr-icon.dsr-nav-action-header-search:before {
    content: "\f13a";
}

.dsr-icon.dsr-nav-action-header-web-call:before {
    content: "\f13b";
}

.dsr-icon.dsr-nav-action-hidden:before {
    content: "\f13c";
}

.dsr-icon.dsr-nav-action-like:before {
    content: "\f13d";
}

.dsr-icon.dsr-nav-action-link:before {
    content: "\f13e";
}

.dsr-icon.dsr-nav-action-list-ok:before {
    content: "\f13f";
}

.dsr-icon.dsr-nav-action-list:before {
    content: "\f140";
}

.dsr-icon.dsr-nav-action-loading:before {
    content: "\f141";
}

.dsr-icon.dsr-nav-action-lock:before {
    content: "\f142";
}

.dsr-icon.dsr-nav-action-mail:before {
    content: "\f143";
}

.dsr-icon.dsr-nav-action-more-action:before {
    content: "\f144";
}

.dsr-icon.dsr-nav-action-network:before {
    content: "\f145";
}

.dsr-icon.dsr-nav-action-point:before {
    content: "\f146";
}

.dsr-icon.dsr-nav-action-print:before {
    content: "\f147";
}

.dsr-icon.dsr-nav-action-refresh:before {
    content: "\f148";
}

.dsr-icon.dsr-nav-action-reload:before {
    content: "\f149";
}

.dsr-icon.dsr-nav-action-save:before {
    content: "\f14a";
}

.dsr-icon.dsr-nav-action-show:before {
    content: "\f14b";
}

.dsr-icon.dsr-nav-action-tick:before {
    content: "\f14c";
}

.dsr-icon.dsr-nav-action-to-do:before {
    content: "\f14d";
}

.dsr-icon.dsr-nav-action-trash:before {
    content: "\f14e";
}

.dsr-icon.dsr-nav-action-unlike:before {
    content: "\f14f";
}

.dsr-icon.dsr-nav-action-unlock:before {
    content: "\f150";
}

.dsr-icon.dsr-nav-action-upload:before {
    content: "\f151";
}

.dsr-icon.dsr-nav-action-wifi:before {
    content: "\f152";
}

.dsr-icon.dsr-nav-arrows-down-left:before {
    content: "\f153";
}

.dsr-icon.dsr-nav-arrows-down-right:before {
    content: "\f154";
}

.dsr-icon.dsr-nav-arrows-down:before {
    content: "\f155";
}

.dsr-icon.dsr-nav-arrows-left:before {
    content: "\f156";
}

.dsr-icon.dsr-nav-arrows-right:before {
    content: "\f157";
}

.dsr-icon.dsr-nav-arrows-up-left:before {
    content: "\f158";
}

.dsr-icon.dsr-nav-arrows-up-right:before {
    content: "\f159";
}

.dsr-icon.dsr-nav-arrows-up:before {
    content: "\f15a";
}

.dsr-icon.dsr-nav-favorite-favorite-full:before {
    content: "\f15b";
}

.dsr-icon.dsr-nav-favorite-favorite-item:before {
    content: "\f15c";
}

.dsr-icon.dsr-nav-favorite-favorite:before {
    content: "\f15d";
}

.dsr-icon.dsr-nav-favorite-love:before {
    content: "\f15e";
}

.dsr-icon.dsr-nav-favorite-wish-list:before {
    content: "\f15f";
}

.dsr-icon.dsr-nav-files-archive:before {
    content: "\f160";
}

.dsr-icon.dsr-nav-files-book:before {
    content: "\f161";
}

.dsr-icon.dsr-nav-files-favorite-page:before {
    content: "\f162";
}

.dsr-icon.dsr-nav-files-internet-page:before {
    content: "\f163";
}

.dsr-icon.dsr-nav-files-pdf:before {
    content: "\f164";
}

.dsr-icon.dsr-nav-files-product-page:before {
    content: "\f165";
}

.dsr-icon.dsr-nav-files-sheet:before {
    content: "\f166";
}

.dsr-icon.dsr-nav-files-specificity-page:before {
    content: "\f167";
}

.dsr-icon.dsr-nav-gestures-double-click-1-finger:before {
    content: "\f168";
}

.dsr-icon.dsr-nav-gestures-double-click-2-fingers:before {
    content: "\f169";
}

.dsr-icon.dsr-nav-gestures-double-click-3-fingers:before {
    content: "\f16a";
}

.dsr-icon.dsr-nav-gestures-extend-2-hands:before {
    content: "\f16b";
}

.dsr-icon.dsr-nav-gestures-extend-diagonally:before {
    content: "\f16c";
}

.dsr-icon.dsr-nav-gestures-extend-horizontally:before {
    content: "\f16d";
}

.dsr-icon.dsr-nav-gestures-extend-vertically:before {
    content: "\f16e";
}

.dsr-icon.dsr-nav-gestures-long-press-1-finger:before {
    content: "\f16f";
}

.dsr-icon.dsr-nav-gestures-long-press-2-fingers:before {
    content: "\f170";
}

.dsr-icon.dsr-nav-gestures-long-press-3-fingers:before {
    content: "\f171";
}

.dsr-icon.dsr-nav-gestures-move-1-finger:before {
    content: "\f172";
}

.dsr-icon.dsr-nav-gestures-move-2-fingers:before {
    content: "\f173";
}

.dsr-icon.dsr-nav-gestures-move-3-fingers:before {
    content: "\f174";
}

.dsr-icon.dsr-nav-gestures-rotation-2-fingers:before {
    content: "\f175";
}

.dsr-icon.dsr-nav-gestures-rotation-2-hands:before {
    content: "\f176";
}

.dsr-icon.dsr-nav-gestures-scroll-1-finger:before {
    content: "\f177";
}

.dsr-icon.dsr-nav-gestures-scroll-2-fingers:before {
    content: "\f178";
}

.dsr-icon.dsr-nav-gestures-scroll-3-fingers:before {
    content: "\f179";
}

.dsr-icon.dsr-nav-gestures-simple-click-1-finger:before {
    content: "\f17a";
}

.dsr-icon.dsr-nav-gestures-simple-click-2-fingers:before {
    content: "\f17b";
}

.dsr-icon.dsr-nav-gestures-simple-click-3-fingers:before {
    content: "\f17c";
}

.dsr-icon.dsr-nav-gestures-slide-1-finger:before {
    content: "\f17d";
}

.dsr-icon.dsr-nav-gestures-slide-2-fingers:before {
    content: "\f17e";
}

.dsr-icon.dsr-nav-gestures-slide-3-fingers:before {
    content: "\f17f";
}

.dsr-icon.dsr-nav-help-bubble-empty:before {
    content: "\f180";
}

.dsr-icon.dsr-nav-help-bubble-focus:before {
    content: "\f181";
}

.dsr-icon.dsr-nav-help-bubble-unfocused:before {
    content: "\f182";
}

.dsr-icon.dsr-nav-help-contact:before {
    content: "\f183";
}

.dsr-icon.dsr-nav-help-hint:before {
    content: "\f184";
}

.dsr-icon.dsr-nav-help-information:before {
    content: "\f185";
}

.dsr-icon.dsr-nav-help-question:before {
    content: "\f186";
}

.dsr-icon.dsr-nav-help-warning:before {
    content: "\f187";
}

.dsr-icon.dsr-nav-location-compass:before {
    content: "\f188";
}

.dsr-icon.dsr-nav-location-flag:before {
    content: "\f189";
}

.dsr-icon.dsr-nav-location-panel:before {
    content: "\f18a";
}

.dsr-icon.dsr-nav-location-pin:before {
    content: "\f18b";
}

.dsr-icon.dsr-nav-location-target:before {
    content: "\f18c";
}

.dsr-icon.dsr-nav-media-backward:before {
    content: "\f18d";
}

.dsr-icon.dsr-nav-media-cookie:before {
    content: "\f18e";
}

.dsr-icon.dsr-nav-media-documents:before {
    content: "\f18f";
}

.dsr-icon.dsr-nav-media-emit:before {
    content: "\f190";
}

.dsr-icon.dsr-nav-media-forward:before {
    content: "\f191";
}

.dsr-icon.dsr-nav-media-medal:before {
    content: "\f192";
}

.dsr-icon.dsr-nav-media-movie-camera:before {
    content: "\f193";
}

.dsr-icon.dsr-nav-media-music:before {
    content: "\f194";
}

.dsr-icon.dsr-nav-media-pad:before {
    content: "\f195";
}

.dsr-icon.dsr-nav-media-pause:before {
    content: "\f196";
}

.dsr-icon.dsr-nav-media-picture:before {
    content: "\f197";
}

.dsr-icon.dsr-nav-media-play:before {
    content: "\f198";
}

.dsr-icon.dsr-nav-media-pod:before {
    content: "\f199";
}

.dsr-icon.dsr-nav-media-qr-code:before {
    content: "\f19a";
}

.dsr-icon.dsr-nav-media-stop:before {
    content: "\f19b";
}

.dsr-icon.dsr-nav-media-trophy:before {
    content: "\f19c";
}

.dsr-icon.dsr-nav-media-volume-high:before {
    content: "\f19d";
}

.dsr-icon.dsr-nav-media-volume-low:before {
    content: "\f19e";
}

.dsr-icon.dsr-nav-media-volume-mute:before {
    content: "\f19f";
}

.dsr-icon.dsr-nav-notification-alarm-clock:before {
    content: "\f1a0";
}

.dsr-icon.dsr-nav-notification-alarm:before {
    content: "\f1a1";
}

.dsr-icon.dsr-nav-notification-calendar:before {
    content: "\f1a2";
}

.dsr-icon.dsr-nav-notification-sandglass:before {
    content: "\f1a3";
}

.dsr-icon.dsr-nav-notification-timer:before {
    content: "\f1a4";
}

.dsr-icon.dsr-nav-pad-beginning:before {
    content: "\f1a5";
}

.dsr-icon.dsr-nav-pad-down-left:before {
    content: "\f1a6";
}

.dsr-icon.dsr-nav-pad-down-right:before {
    content: "\f1a7";
}

.dsr-icon.dsr-nav-pad-down:before {
    content: "\f1a8";
}

.dsr-icon.dsr-nav-pad-end:before {
    content: "\f1a9";
}

.dsr-icon.dsr-nav-pad-horizontally:before {
    content: "\f1aa";
}

.dsr-icon.dsr-nav-pad-left:before {
    content: "\f1ab";
}

.dsr-icon.dsr-nav-pad-right:before {
    content: "\f1ac";
}

.dsr-icon.dsr-nav-pad-up-left:before {
    content: "\f1ad";
}

.dsr-icon.dsr-nav-pad-up-right:before {
    content: "\f1ae";
}

.dsr-icon.dsr-nav-pad-up:before {
    content: "\f1af";
}

.dsr-icon.dsr-nav-pad-vertically:before {
    content: "\f1b0";
}

.dsr-icon.dsr-nav-users-community:before {
    content: "\f1b1";
}

.dsr-icon.dsr-nav-users-group:before {
    content: "\f1b2";
}

.dsr-icon.dsr-nav-users-user:before {
    content: "\f1b3";
}

.dsr-icon.dsr-others-box:before {
    content: "\f1b4";
}

.dsr-icon.dsr-others-devices-app:before {
    content: "\f1b5";
}

.dsr-icon.dsr-others-devices-desktop:before {
    content: "\f1b6";
}

.dsr-icon.dsr-others-devices-laptop:before {
    content: "\f1b7";
}

.dsr-icon.dsr-others-devices-smartphone:before {
    content: "\f1b8";
}

.dsr-icon.dsr-others-devices-tablet:before {
    content: "\f1b9";
}

.dsr-icon.dsr-others-devices-tv:before {
    content: "\f1ba";
}

.dsr-icon.dsr-others-emojis-angry:before {
    content: "\f1bb";
}

.dsr-icon.dsr-others-emojis-chill:before {
    content: "\f1bc";
}

.dsr-icon.dsr-others-emojis-confused:before {
    content: "\f1bd";
}

.dsr-icon.dsr-others-emojis-disappointed:before {
    content: "\f1be";
}

.dsr-icon.dsr-others-emojis-fine:before {
    content: "\f1bf";
}

.dsr-icon.dsr-others-emojis-happy:before {
    content: "\f1c0";
}

.dsr-icon.dsr-others-emojis-surprised:before {
    content: "\f1c1";
}

.dsr-icon.dsr-others-handicap:before {
    content: "\f1c2";
}

.dsr-icon.dsr-others-luggage:before {
    content: "\f1c3";
}

.dsr-icon.dsr-others-organisation:before {
    content: "\f1c4";
}

.dsr-icon.dsr-others-usb:before {
    content: "\f1c5";
}

.dsr-icon.dsr-vehicles-cars-berline:before {
    content: "\f1c6";
}

.dsr-icon.dsr-vehicles-cars-break:before {
    content: "\f1c7";
}

.dsr-icon.dsr-vehicles-cars-campervan:before {
    content: "\f1c8";
}

.dsr-icon.dsr-vehicles-cars-cdv:before {
    content: "\f1c9";
}

.dsr-icon.dsr-vehicles-cars-chassis-cab:before {
    content: "\f1ca";
}

.dsr-icon.dsr-vehicles-cars-city-car:before {
    content: "\f1cb";
}

.dsr-icon.dsr-vehicles-cars-compact-van:before {
    content: "\f1cc";
}

.dsr-icon.dsr-vehicles-cars-configurator:before {
    content: "\f1cd";
}

.dsr-icon.dsr-vehicles-cars-convertible:before {
    content: "\f1ce";
}

.dsr-icon.dsr-vehicles-cars-coupe:before {
    content: "\f1cf";
}

.dsr-icon.dsr-vehicles-cars-dump-truck:before {
    content: "\f1d0";
}

.dsr-icon.dsr-vehicles-cars-emergency:before {
    content: "\f1d1";
}

.dsr-icon.dsr-vehicles-cars-f1:before {
    content: "\f1d2";
}

.dsr-icon.dsr-vehicles-cars-family:before {
    content: "\f1d3";
}

.dsr-icon.dsr-vehicles-cars-hatchback:before {
    content: "\f1d4";
}

.dsr-icon.dsr-vehicles-cars-large-van:before {
    content: "\f1d5";
}

.dsr-icon.dsr-vehicles-cars-minibus:before {
    content: "\f1d6";
}

.dsr-icon.dsr-vehicles-cars-mpv:before {
    content: "\f1d7";
}

.dsr-icon.dsr-vehicles-cars-passenger-cdv:before {
    content: "\f1d8";
}

.dsr-icon.dsr-vehicles-cars-pick-up:before {
    content: "\f1d9";
}

.dsr-icon.dsr-vehicles-cars-shuttle:before {
    content: "\f1da";
}

.dsr-icon.dsr-vehicles-cars-suv:before {
    content: "\f1db";
}

.dsr-icon.dsr-vehicles-cars-tow-truck:before {
    content: "\f1dc";
}

.dsr-icon.dsr-vehicles-cockpit-air-conditioning:before {
    content: "\f1dd";
}

.dsr-icon.dsr-vehicles-cockpit-automatic-gear:before {
    content: "\f1de";
}

.dsr-icon.dsr-vehicles-cockpit-automatic-stick:before {
    content: "\f1df";
}

.dsr-icon.dsr-vehicles-cockpit-interior:before {
    content: "\f1e0";
}

.dsr-icon.dsr-vehicles-cockpit-key:before {
    content: "\f1e1";
}

.dsr-icon.dsr-vehicles-cockpit-manual-gear:before {
    content: "\f1e2";
}

.dsr-icon.dsr-vehicles-cockpit-manual-stick:before {
    content: "\f1e3";
}

.dsr-icon.dsr-vehicles-cockpit-seat:before {
    content: "\f1e4";
}

.dsr-icon.dsr-vehicles-cockpit-speedometer:before {
    content: "\f1e5";
}

.dsr-icon.dsr-vehicles-cockpit-steering-wheel:before {
    content: "\f1e6";
}

.dsr-icon.dsr-vehicles-energy-battery-level-0:before {
    content: "\f1e7";
}

.dsr-icon.dsr-vehicles-energy-battery-level-1:before {
    content: "\f1e8";
}

.dsr-icon.dsr-vehicles-energy-battery-level-2:before {
    content: "\f1e9";
}

.dsr-icon.dsr-vehicles-energy-battery-level-3:before {
    content: "\f1ea";
}

.dsr-icon.dsr-vehicles-energy-battery-level-4:before {
    content: "\f1eb";
}

.dsr-icon.dsr-vehicles-energy-battery-pricing:before {
    content: "\f1ec";
}

.dsr-icon.dsr-vehicles-energy-battery:before {
    content: "\f1ed";
}

.dsr-icon.dsr-vehicles-energy-charge:before {
    content: "\f1ee";
}

.dsr-icon.dsr-vehicles-energy-data-emission:before {
    content: "\f1ef";
}

.dsr-icon.dsr-vehicles-energy-electric-vehicle:before {
    content: "\f1f0";
}

.dsr-icon.dsr-vehicles-energy-fast-charging-port-:before {
    content: "\f1f1";
}

.dsr-icon.dsr-vehicles-energy-fuel-pump:before {
    content: "\f1f2";
}

.dsr-icon.dsr-vehicles-energy-green-emission:before {
    content: "\f1f3";
}

.dsr-icon.dsr-vehicles-energy-hybrid:before {
    content: "\f1f4";
}

.dsr-icon.dsr-vehicles-energy-no-battery:before {
    content: "\f1f5";
}

.dsr-icon.dsr-vehicles-energy-plug-connected:before {
    content: "\f1f6";
}

.dsr-icon.dsr-vehicles-energy-plug-disconnected:before {
    content: "\f1f7";
}

.dsr-icon.dsr-vehicles-energy-plug-hybrid:before {
    content: "\f1f8";
}

.dsr-icon.dsr-vehicles-energy-plug:before {
    content: "\f1f9";
}

.dsr-icon.dsr-vehicles-energy-standart-charging-port:before {
    content: "\f1fa";
}

.dsr-icon.dsr-vehicles-energy-wall-charging-port:before {
    content: "\f1fb";
}

.dsr-icon.dsr-vehicles-exterior-brake:before {
    content: "\f1fc";
}

.dsr-icon.dsr-vehicles-exterior-car-body:before {
    content: "\f1fd";
}

.dsr-icon.dsr-vehicles-exterior-car-hood:before {
    content: "\f1fe";
}

.dsr-icon.dsr-vehicles-exterior-door:before {
    content: "\f1ff";
}

.dsr-icon.dsr-vehicles-exterior-fog-lamp:before {
    content: "\f200";
}

.dsr-icon.dsr-vehicles-exterior-high-beam:before {
    content: "\f201";
}

.dsr-icon.dsr-vehicles-exterior-hubcap:before {
    content: "\f202";
}

.dsr-icon.dsr-vehicles-exterior-low-beam:before {
    content: "\f203";
}

.dsr-icon.dsr-vehicles-exterior-no-light:before {
    content: "\f204";
}

.dsr-icon.dsr-vehicles-exterior-roof-bar:before {
    content: "\f205";
}

.dsr-icon.dsr-vehicles-exterior-roof-box:before {
    content: "\f206";
}

.dsr-icon.dsr-vehicles-exterior-snow-box:before {
    content: "\f207";
}

.dsr-icon.dsr-vehicles-exterior-spare-parts:before {
    content: "\f208";
}

.dsr-icon.dsr-vehicles-exterior-tow-ball:before {
    content: "\f209";
}

.dsr-icon.dsr-vehicles-exterior-transmission:before {
    content: "\f20a";
}

.dsr-icon.dsr-vehicles-exterior-wheel:before {
    content: "\f20b";
}

.dsr-icon.dsr-vehicles-exterior-windshield:before {
    content: "\f20c";
}

.dsr-icon.dsr-vehicles-exterior-wiper:before {
    content: "\f20d";
}

.dsr-icon.dsr-vehicles-maintenance-antifreeze:before {
    content: "\f20e";
}

.dsr-icon.dsr-vehicles-maintenance-check-oil:before {
    content: "\f20f";
}

.dsr-icon.dsr-vehicles-maintenance-draining:before {
    content: "\f210";
}

.dsr-icon.dsr-vehicles-maintenance-exchange:before {
    content: "\f211";
}

.dsr-icon.dsr-vehicles-maintenance-oil-ok:before {
    content: "\f212";
}

.dsr-icon.dsr-vehicles-maintenance-reparation:before {
    content: "\f213";
}

.dsr-icon.dsr-vehicles-maintenance-replace:before {
    content: "\f214";
}

.dsr-icon.dsr-vehicles-maintenance-return:before {
    content: "\f215";
}

.dsr-icon.dsr-vehicles-maintenance-tire-pressure:before {
    content: "\f216";
}

.dsr-icon.dsr-vehicles-maintenance-wheel-ok:before {
    content: "\f217";
}

.dsr-icon.dsr-vehicles-maintenance-wiper-ok:before {
    content: "\f218";
}

.dsr-icon.dsr-vehicles-motor-electric:before {
    content: "\f219";
}

.dsr-icon.dsr-vehicles-motor-emptying:before {
    content: "\f21a";
}

.dsr-icon.dsr-vehicles-motor-engine-belt:before {
    content: "\f21b";
}

.dsr-icon.dsr-vehicles-motor-exhaust-pipe:before {
    content: "\f21c";
}

.dsr-icon.dsr-vehicles-motor-horn:before {
    content: "\f21d";
}

.dsr-icon.dsr-vehicles-motor-kilowatts:before {
    content: "\f21e";
}

.dsr-icon.dsr-vehicles-motor-mecanical-link:before {
    content: "\f21f";
}

.dsr-icon.dsr-vehicles-motor-motor-boost:before {
    content: "\f220";
}

.dsr-icon.dsr-vehicles-motor-motor-sport:before {
    content: "\f221";
}

.dsr-icon.dsr-vehicles-motor-motor:before {
    content: "\f222";
}

.dsr-icon.dsr-vehicles-motor-no-oil:before {
    content: "\f223";
}

.dsr-icon.dsr-vehicles-motor-oil-compare:before {
    content: "\f224";
}

.dsr-icon.dsr-vehicles-motor-oil-level-0:before {
    content: "\f225";
}

.dsr-icon.dsr-vehicles-motor-oil-level-1:before {
    content: "\f226";
}

.dsr-icon.dsr-vehicles-motor-oil-level-2:before {
    content: "\f227";
}

.dsr-icon.dsr-vehicles-motor-oil-level-3:before {
    content: "\f228";
}

.dsr-icon.dsr-vehicles-motor-oil-level-4:before {
    content: "\f229";
}

.dsr-icon.dsr-vehicles-motor-oil-motor:before {
    content: "\f22a";
}

.dsr-icon.dsr-vehicles-motor-piston:before {
    content: "\f22b";
}

.dsr-icon.dsr-vehicles-motor-pistons:before {
    content: "\f22c";
}

.dsr-icon.dsr-vehicles-others-city-road:before {
    content: "\f22d";
}

.dsr-icon.dsr-vehicles-others-country-road:before {
    content: "\f22e";
}

.dsr-icon.dsr-vehicles-others-cycle:before {
    content: "\f22f";
}

.dsr-icon.dsr-vehicles-others-favorite-car:before {
    content: "\f230";
}

.dsr-icon.dsr-vehicles-others-helmet:before {
    content: "\f231";
}

.dsr-icon.dsr-vehicles-others-highway:before {
    content: "\f232";
}

.dsr-icon.dsr-vehicles-others-parking:before {
    content: "\f233";
}

.dsr-icon.dsr-vehicles-others-weight:before {
    content: "\f234";
}

.dsr-icon.dsr-vehicles-status-braking:before {
    content: "\f235";
}

.dsr-icon.dsr-vehicles-status-cars-park:before {
    content: "\f236";
}

.dsr-icon.dsr-vehicles-status-collision:before {
    content: "\f237";
}

.dsr-icon.dsr-vehicles-status-detection:before {
    content: "\f238";
}

.dsr-icon.dsr-vehicles-status-driving:before {
    content: "\f239";
}

.dsr-icon.dsr-vehicles-status-fleet:before {
    content: "\f23a";
}

.dsr-icon.dsr-vehicles-status-no-signal:before {
    content: "\f23b";
}

.dsr-icon.dsr-vehicles-status-progression:before {
    content: "\f23c";
}

.dsr-icon.dsr-vehicles-status-signal-down:before {
    content: "\f23d";
}

.dsr-icon.dsr-vehicles-status-signal-ok:before {
    content: "\f23e";
}

.dsr-icon.dsr-vehicles-status-signal-up:before {
    content: "\f23f";
}

.dsr-icon.dsr-weather-cloudy-1:before {
    content: "\f240";
}

.dsr-icon.dsr-weather-cloudy-2:before {
    content: "\f241";
}

.dsr-icon.dsr-weather-lightning:before {
    content: "\f242";
}

.dsr-icon.dsr-weather-partly-cloudy-1:before {
    content: "\f243";
}

.dsr-icon.dsr-weather-partly-cloudy-2:before {
    content: "\f244";
}

.dsr-icon.dsr-weather-partly-lightning-1:before {
    content: "\f245";
}

.dsr-icon.dsr-weather-partly-lightning:before {
    content: "\f246";
}

.dsr-icon.dsr-weather-partly-raining-1:before {
    content: "\f247";
}

.dsr-icon.dsr-weather-partly-raining-2:before {
    content: "\f248";
}

.dsr-icon.dsr-weather-partly-snowing:before {
    content: "\f249";
}

.dsr-icon.dsr-weather-raining-1:before {
    content: "\f24a";
}

.dsr-icon.dsr-weather-raining-2:before {
    content: "\f24b";
}

.dsr-icon.dsr-weather-raining-snowing:before {
    content: "\f24c";
}

.dsr-icon.dsr-weather-snowing:before {
    content: "\f24d";
}

.dsr-icon.dsr-weather-sunny:before {
    content: "\f24e";
}

.dsr-icon.dsr-weather-windy:before {
    content: "\f24f";
}

:root {
    --dsr-color-secondary: #FFDB75;
    --dsr-color-orange-dark: #EB6500;
    --dsr-color-text: #000000;
    --dsr-color-background: #EEEEEE;
    --dsr-color-darkgreen-light: #7CF7CF;
    --dsr-color-info: #FFCC33;
    --dsr-color-orange: #FF8200;
    --dsr-color-darkblue: #0033A0;
    --dsr-color-darkpurple: #5C068C;
    --dsr-color-light-1: #FAF9F8;
    --dsr-color-text-link: #0091DA;
    --dsr-color-red-light: #FF6E6A;
    --dsr-color-red-dark: #8C0012;
    --dsr-color-blueduck: #009CA6;
    --dsr-color-light-2: #F1F1F2;
    --dsr-color-ze-light: #B2DEF3;
    --dsr-color-warning: #FF8200;
    --dsr-color-hover: #BBBCBC;
    --dsr-color-darkgreen-hover: #55D2AC;
    --dsr-color-yellow: #FFCC33;
    --dsr-color-text-secondary: #999999;
    --dsr-color-darkgreen-dark: #006747;
    --dsr-color-black: #000000;
    --dsr-color-orange-light: #FFC14C;
    --dsr-color-red-hover: #E85252;
    --dsr-color-darkblue-light: #006ED6;
    --dsr-color-ze-hover: #4CB2E5;
    --dsr-color-dark-1: #888B8D;
    --dsr-color-blue: #0077C8;
    --dsr-color-primary: #FFCC33;
    --dsr-color-success: #73C366;
    --dsr-color-dark-2: #53565A;
    --dsr-color-darkpurple-light: #AD5DDE;
    --dsr-color-green: #73C366;
    --dsr-color-blueduck-light: #77EEF8;
    --dsr-color-dark-3: #333333;
    --dsr-color-purple: #9E007E;
    --dsr-color-yellow-light: #FFF8E6;
    --dsr-color-orange-hover: #FFA51C;
    --dsr-color-purple-dark: #810065;
    --dsr-color-darkblue-hover: #1749C4;
    --dsr-color-error: #CB333B;
    --dsr-color-green-dark: #4EA147;
    --dsr-color-white: #FFFFFF;
    --dsr-color-darkpurple-hover: #9143C2;
    --dsr-color-blue-dark: #0048A0;
    --dsr-color-blueduck-hover: #58D1DC;
    --dsr-color-yellow-hover: #FFDB75;
    --dsr-color-blue-light: #7AC5FF;
    --dsr-color-green-light: #A7FB9A;
    --dsr-color-purple-light: #D750B3;
    --dsr-color-darkgreen: #009B77;
    --dsr-color-blueduck-dark: #006772;
    --dsr-color-blue-hover: #5BAAFF;
    --dsr-color-darkpurple-dark: #430068;
    --dsr-color-red: #CB333B;
    --dsr-color-green-hover: #8BDE7F;
    --dsr-color-purple-hover: #BA3198;
    --dsr-color-darkblue-dark: #002280;
    --dsr-color-ze: #0091DA
}