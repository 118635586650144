@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Light.woff2') format('woff2'),
  url('RenaultGroup-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Book.woff2') format('woff2'),
  url('RenaultGroup-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Regular.woff2') format('woff2'),
  url('RenaultGroup-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Semibold.woff2') format('woff2'),
  url('RenaultGroup-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Bold.woff2') format('woff2'),
  url('RenaultGroup-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup';
  src: url('RenaultGroup-Extrabold.woff2') format('woff2'),
  url('RenaultGroup-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RenaultGroup-Variable';
  src: url('RenaultGroup-Variable.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}